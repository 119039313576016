<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Total kostpris
          </v-card-title>

          <v-card-text>
            DKK {{ totalCost | formatNumberFixed2 }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-title>
            Total salgspris
          </v-card-title>

          <v-card-text>
            DKK {{ totalPrice | formatNumberFixed2 }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-title>
            Totalt materialer (kostpris)
          </v-card-title>

          <v-card-text>
            DKK {{ totalParts | formatNumberFixed2 }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-title>
            Total arbejdstimer
          </v-card-title>

          <v-card-text>
            {{ totalHours | formatNumberFixed2 }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <SpecificationMaterialTable :items="aggregatedParts" :locked="$attrs.locked" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <SpecificationLaborTable :items="aggregatedLabors" :locked="$attrs.locked" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Aftaler / tilbud
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="approvalHeaders"
              :items="approvals"
            >
              <template #[`item.createdOn`]="{ item }">
                {{ item.createdOn | formatDateTime }}
              </template>
              <template #[`item.deadline`]="{ item }">
                {{ item.deadline | formatDate }}
              </template>
              <template #[`item.status`]="{ item }">
                <ApprovalStatus v-bind="item" />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SpecificationMaterialTable from '@/components/specification/SpecificationMaterialTable.vue';
import SpecificationLaborTable from '@/components/specification/SpecificationLaborTable.vue';
import ApprovalStatus from '@/components/approval/ApprovalStatus.vue';
import ActivityLog from '@/components/ActivityLog.vue';

export default {
  name: 'SpecificationDashboard',
  components: {
    SpecificationMaterialTable,
    SpecificationLaborTable,
    ApprovalStatus,
    ActivityLog
  },
  props: {
    approvals: {
      type: Array,
      required: true
    },
    spec: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      approvalHeaders: [
        { text: 'Kode', value: 'code' },
        { text: 'Oprettelsesdato', value: 'createdOn' },
        { text: 'Oprettet af', value: 'createdByFullNameWithDetails' },
        { text: 'Deadline', value: 'deadline' },
        { text: 'Status', value: 'status', align: 'end' }
      ],
      deleteMenu: false,
      deleting: false,
      editTitle: false,
      locking: false,
      saving: false,
      showRightDrawer: false,
      updatingLock: false
    }
  },
  computed: {
    sections() {
      return this.spec.groups?.flatMap(x => x.sections);
    },
    totalCost() {
      return this.sections.reduce((prev, current) => {
        const parts = current.parts.reduce((a, b) => a + b.costPrice * b.totalQuantity, 0);
        const labors = current.labors.reduce((a, b) => a + b.hourCostPrice * b.totalHours, 0);

        return prev + (parts + labors);
      }, 0);
    },
    totalPrice() {
      return this.sections.reduce((prev, current) => {
        const parts = current.parts.reduce((a, b) => a + b.price * b.totalQuantity, 0);
        const labors = current.labors.reduce((a, b) => a + b.hourPrice * b.totalHours, 0);

        return prev + (parts + labors);
      }, 0);
    },
    totalParts() {
      return this.sections.reduce((prev, current) => {
        return prev + current.parts.reduce((a, b) => a + b.costPrice * b.totalQuantity, 0);
      }, 0);
    },
    totalHours() {
      return this.sections.reduce((prev, current) => {
        return prev + current.labors.reduce((a, b) => a + b.totalHours, 0);
      }, 0);
    },
    aggregatedParts() {
      let allParts = this.sections.map(x => x.parts).flat();
      const result = [];

      allParts.forEach(part => {
        if (result.some(x => x.part_Id == part.part_Id)) return;

        result.push({
          ...part,
          quantity: allParts.filter(x => x.part_Id == part.part_Id).reduce((a, b) => a + b.totalQuantity, 0)
        });
      });

      return result;
    },
    aggregatedLabors() {
      let allLabors = this.sections.map(x => x.labors).flat();
      const result = [];

      allLabors.forEach(labor => {
        if (result.some(x => x.customerRole_Id == labor.customerRole_Id)) return;

        result.push({
          ...labor,
          hours: allLabors.filter(x => x.customerRole_Id == labor.customerRole_Id).reduce((a, b) => a + b.totalHours, 0)
        });
      });

      return result;
    }
  }
}
</script>